/*
 * SPDX-FileCopyrightText: 2021 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.cell {
  &.selected {
    background: var(--bs-primary);
    border-color: transparent;
  }
  background: transparent;
  border-color: var(--bs-secondary);
  margin: 1px;
  border-radius: 2px;
  border: solid 1px;
}

.table-container {
  display: grid;
  grid-template-columns: repeat(10, 15px [col-start]);
  grid-template-rows: repeat(8, 15px [row-start]);
}
