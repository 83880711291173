/*!
 * SPDX-FileCopyrightText: 2023 The HedgeDoc developers (see AUTHORS file)
 *
 * SPDX-License-Identifier: AGPL-3.0-only
 */

.cursor-0 {
  --color: #780c0c;
}

.cursor-1 {
  --color: #ff1111;
}

.cursor-2 {
  --color: #1149ff;
}

.cursor-3 {
  --color: #11ff39;
}

.cursor-4 {
  --color: #cb11ff;
}

.cursor-5 {
  --color: #ffff00;
}

.cursor-6 {
  --color: #00fff2;
}

.cursor-7 {
  --color: #ff8000;
}
